import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { languages } from 'countries-list';
import { useTranslation } from 'react-i18next';
import { setLanguage } from '~/features/settings/actions.ts';
import { getLanguage } from '~/features/settings/slice.ts';
import { useAppDispatch, useAppSelector } from '~/hooks/store.ts';

import { supportedLanguages, type Language } from '~/i18n.ts';

const formatItem = (lang: Language) => {
  const data = languages[lang];
  if (data) {
    let { name, native } = data;
    native ??= name;
    return name === native ? name : `${native} (${name})`;
  }

  return String(lang);
};

/**
 * Component that shows a language selector dropdown in the settings window.
 */
export default function LanguageSelector() {
  const { t } = useTranslation();
  const language = useAppSelector(getLanguage);
  const dispatch = useAppDispatch();

  return (
    <TextField
      label={t('LanguageSelector.label')}
      select
      fullWidth
      value={language}
      onChange={(event) => {
        dispatch(setLanguage(event.target.value as any as Language));
      }}
    >
      {supportedLanguages.map((lang) => (
        <MenuItem key={lang} value={lang}>
          {formatItem(lang)}
        </MenuItem>
      ))}
    </TextField>
  );
}
