import isNil from 'lodash-es/isNil';

import type { SignalProcessingContext } from './context/base.ts';
import type { ReadOptions } from './types.ts';

/**
 * Interface specification for a single node in a signal processing context.
 *
 * This interface is meant to be roughly API-compatible with AudioNode from the
 * Web Audio API.
 */
export interface SignalProcessingNode {
  /**
   * The context in which this node lives.
   */
  readonly context: SignalProcessingContext;

  /**
   * Returns an array containing the input connections of the node.
   *
   * Each input may have one connected node only, therefore the i-th element
   * in this array always corresponds to input i.
   */
  readonly inputs: ReadonlyArray<Connection | undefined>;

  /**
   * Returns an array containing the output connections of the node.
   *
   * Outputs may be connected to multiple other nodes to allow fan-out.
   */
  readonly outputs: readonly Connection[];

  /**
   * The number of inputs of the node.
   */
  readonly numberOfInputs: number;

  /**
   * The number of outputs of the node.
   */
  readonly numberOfOutputs: number;

  /**
   * The number of channels.
   */
  readonly channelCount: number;

  /**
   * Connects the output of this node to another node.
   */
  connect: (
    destination: SignalProcessingNode,
    outputIndex?: number,
    inputIndex?: number,
  ) => SignalProcessingNode;

  /**
   * Disconnects the output of this node from another node.
   */
  disconnect: (
    destination?: SignalProcessingNode,
    outputIndex?: number,
    inputIndex?: number,
  ) => void;

  /**
   * Reads at most the given number of samples into the given buffer.
   *
   * For source nodes (i.e. no inputs), this function should generate samples and
   * write them into the given buffer. For filters and destination nodes, this
   * function should read samples from its inputs, do the necessary processing
   * on them and then write the result into the output buffer.
   *
   * @param buffer  the buffer to read into
   * @param options options that control how many samples to read and where to
   *        put them in the buffer
   * @returns  the number of samples that were added to the buffer
   */
  read: (buffer: Float32Array, options: ReadOptions) => number;
}

/**
 * Object identifying the endpoint of a connection. The endpoint is defined by
 * a signal processing node and the index of the input or output that the
 * connection is connected to.
 */
export interface Endpoint {
  node: SignalProcessingNode;
  index: number;
}

/**
 * Directed connection between two signal processing nodes.
 */
export interface Connection {
  /** The source node of the connection */
  source: Endpoint;

  /** The destination node of the connection */
  destination: Endpoint;
}

export abstract class SignalProcessingNodeBase implements SignalProcessingNode {
  _inputs: Array<Connection | undefined> = [];
  _outputs: Connection[] = [];

  constructor(public readonly context: SignalProcessingContext) {}

  connect(
    destination: SignalProcessingNode,
    outputIndex = 0,
    inputIndex = 0,
  ): SignalProcessingNode {
    let conn;

    conn = destination.inputs[inputIndex];
    if (conn) {
      if (conn.source.node !== this || conn.source.index !== outputIndex) {
        throw new Error(
          `Destination node input ${inputIndex} is already connected somewhere else.`,
        );
      } else {
        return destination;
      }
    }

    conn = {
      source: { node: this, index: outputIndex },
      destination: { node: destination, index: inputIndex },
    };

    (destination as any as SignalProcessingNodeBase)._inputs[inputIndex] = conn;
    this._outputs[outputIndex] = conn;

    return destination;
  }

  disconnect(
    destination?: SignalProcessingNode,
    outputIndex?: number,
    inputIndex?: number,
  ) {
    if (destination) {
      if (isNil(inputIndex)) {
        for (const [inputIndex, conn] of Object.entries(destination.inputs)) {
          if (
            conn?.source.node === this &&
            (outputIndex === undefined || conn?.source.index === outputIndex)
          ) {
            (destination as any as SignalProcessingNodeBase)._inputs[
              Number(inputIndex)
            ] = undefined;

            const index = this._outputs.indexOf(conn);
            this._outputs.splice(index, 1);
          }
        }
      } else {
        const conn = destination.inputs[inputIndex];
        if (
          conn?.source.node === this &&
          (outputIndex === undefined || conn?.source.index === outputIndex)
        ) {
          (destination as any as SignalProcessingNodeBase)._inputs[
            inputIndex ?? 0
          ] = undefined;

          const index = this._outputs.indexOf(conn);
          this._outputs.splice(index, 1);
        }
      }
    } else {
      for (const conn of this._outputs) {
        const { destination } = conn;
        (destination.node as any as SignalProcessingNodeBase)._inputs[
          destination.index
        ] = undefined;
      }

      this._outputs.length = 0;
    }
  }

  get inputs(): Array<Connection | undefined> {
    if (this._inputs.length < this.numberOfInputs) {
      this._inputs.length = this.numberOfInputs;
    }

    return this._inputs;
  }

  get outputs(): readonly Connection[] {
    return this._outputs;
  }

  abstract get channelCount(): number;
  abstract get numberOfInputs(): number;
  abstract get numberOfOutputs(): number;

  abstract read(buffer: Float32Array, options: ReadOptions): number;
}
