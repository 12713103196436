import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { Language } from '~/i18n.ts';

export interface SettingsState {
  autoIncreaseRecordingIndex: boolean;
  language: Language;
}

const initialState: SettingsState = {
  autoIncreaseRecordingIndex: true,
  language: Language.EN,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    updateSettings(state, { payload }: PayloadAction<Partial<SettingsState>>) {
      Object.assign(state, payload);
    },
  },
  selectors: {
    getLanguage: (state) => state.language,
    shouldIncreaseRecordingIndexAutomatically: (state) =>
      state.autoIncreaseRecordingIndex,
  },
});

export const { getLanguage, shouldIncreaseRecordingIndexAutomatically } =
  settingsSlice.selectors;
export const { updateSettings } = settingsSlice.actions;

export default settingsSlice.reducer;
