import { blue as primary, yellow as secondary } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import type { ThemeProviderProps } from '@mui/material/styles/ThemeProvider';
import { useMemo } from 'react';
import type { ToastOptions } from 'react-hot-toast';
import { getLanguage } from './features/settings/slice.ts';
import { useAppSelector } from './hooks/store.ts';
import { languageToMuiLocalizations } from './i18n.ts';

/**
 * A custom MUI theme for this app.
 */
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary,
    secondary,
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
    },
  },
});

/**
 * Custom toast style for this app.
 */
export const toastOptions: ToastOptions = {
  position: 'bottom-center',
  style: {
    background: '#333',
    color: '#fff',
  },
};

type LocalizedThemeProviderProps = Omit<ThemeProviderProps, 'theme'>;

export function LocalizedThemeProvider({
  children,
}: LocalizedThemeProviderProps) {
  const language = useAppSelector(getLanguage);
  const loc = languageToMuiLocalizations[language];
  const localizedTheme = useMemo(() => createTheme(theme, ...loc), [loc]);

  return <ThemeProvider theme={localizedTheme}>{children}</ThemeProvider>;
}

export default theme;
