import type { SignalProcessingContext } from '../context/base.ts';
import type { ReadOptions } from '../types.ts';
import { SignalFilterBase } from './base.ts';

export interface GainNodeProps {
  gain?: number;
}

/**
 * Applies linear amplification to an input signal.
 */
export class GainNode extends SignalFilterBase {
  static readonly defaults: Readonly<Required<GainNodeProps>> = {
    gain: 1,
  };

  channelCount = 1;
  gain = 1;
  numberOfInputs = 1;
  numberOfOutputs = 1;

  constructor(context: SignalProcessingContext, props: GainNodeProps = {}) {
    super(context);
    Object.assign(this, { ...GainNode.defaults, ...props });
  }

  read(buffer: Float32Array, options: ReadOptions): number {
    const numSamples = this._readInput(buffer, 0, options);

    if (this.gain !== 1) {
      for (let i = 0; i < numSamples; i++) {
        buffer[i] *= this.gain;
      }
    }

    return numSamples;
  }
}
