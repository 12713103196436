import { Language } from '~/i18n.ts';
import type { AppThunk } from '~/store.ts';

import { getLanguage, updateSettings } from './slice.ts';

export const setLanguage = (language: Language) => updateSettings({ language });

export const toggleLanguage = (): AppThunk => async (dispatch, getState) => {
  const language = getLanguage(getState());
  const newLanguage = language === Language.EN ? Language.HU : Language.EN;
  dispatch(setLanguage(newLanguage));
};
