import Refresh from '@mui/icons-material/Refresh';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import useStorage from '~/hooks/useStorage.ts';

/**
 * Component showing the sidebar of the recording view.
 */
export default function RefreshStorageListItem() {
  const { t } = useTranslation();
  const { refresh } = useStorage();
  return (
    <ListItem disablePadding>
      <ListItemButton onClick={refresh}>
        <ListItemIcon>
          <Refresh />
        </ListItemIcon>
        <ListItemText primary={t('Actions.refresh')} />
      </ListItemButton>
    </ListItem>
  );
}
