import {
  SignalProcessingNodeBase,
  type SignalProcessingNode,
} from '../signals.ts';

/**
 * Interface specification for objects that can act as signal sources.
 */
export interface SignalSource extends SignalProcessingNode {}

export abstract class SignalSourceBase
  extends SignalProcessingNodeBase
  implements SignalSource
{
  numberOfInputs = 0;
}
