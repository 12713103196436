import { useIntervalEffect } from '@react-hookz/web';
import isNil from 'lodash-es/isNil';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LabeledSidebarText from '~/components/LabeledSidebarText.tsx';
import {
  getRecordingStartTime,
  isRecording,
} from '~/features/recording/slice.ts';
import { useAppSelector } from '~/hooks/store.ts';

const notRunning = '–:––';

const formatDurationMsec = (value: number | undefined): string => {
  if (isNil(value) || Number.isNaN(value) || !Number.isFinite(value)) {
    return notRunning;
  }

  if (value < 0) {
    return '-' + formatDurationMsec(-value);
  }

  value = Math.floor(value / 1000);

  const hours = Math.floor(value / 3600);
  value %= 3600;

  const minutes = Math.floor(value / 60);
  const seconds = value % 60;

  if (hours > 0) {
    return `${hours}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  }

  return `${minutes}:${String(seconds).padStart(2, '0')}`;
};

export default function ElapsedTime() {
  const [duration, setDuration] = useState(notRunning);
  const { t } = useTranslation();

  const recording = useAppSelector(isRecording);
  const startedAt = useAppSelector(getRecordingStartTime);

  useIntervalEffect(
    () => {
      setDuration(
        formatDurationMsec(
          isNil(startedAt) ? undefined : Date.now() - startedAt,
        ),
      );
    },
    recording ? 200 : undefined,
  );

  return (
    <LabeledSidebarText
      label={t('Sidebar.elapsedTime')}
      primaryText={duration}
    />
  );
}
