import { deepOrange, green, lightBlue, yellow } from '@mui/material/colors';
import Stack from '@mui/material/Stack';
import { useMeasure } from '@react-hookz/web';
import Placeholder from '~/components/Placeholder.tsx';
import { getRecordingChannels } from '~/features/recording/selectors.ts';
import { getPlayheadPosition } from '~/features/recording/slice.ts';
import { useAppSelector } from '~/hooks/store.ts';
import { SimpleLineChart } from './SimpleLineChart.tsx';

const yRange: [number, number] = [-1, 1];

const colors: string[] = [
  green.A400,
  yellow.A400,
  deepOrange.A400,
  lightBlue.A400,
];

export default function Channels() {
  const [measurements, ref] = useMeasure<HTMLDivElement>();
  const { height = 400 } = measurements ?? {};
  const playheadPosition = useAppSelector(getPlayheadPosition);
  const channels = useAppSelector(getRecordingChannels);
  const channelHeight = height / Math.max(channels.length, 1);

  return channels.length > 0 ? (
    <Stack direction="column" sx={{ flex: 1, height: '100%' }} ref={ref}>
      {channels.map((channel) => (
        <SimpleLineChart
          key={channel.id}
          minHeight={channelHeight}
          maxHeight={channelHeight}
          numSamples={500}
          playheadPosition={playheadPosition}
          samples={channel.samples}
          yRange={yRange}
          color={colors[channel.index % colors.length]}
        />
      ))}
    </Stack>
  ) : (
    <Placeholder label="No recording yet" />
  );
}
