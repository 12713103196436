import { t } from 'i18next';
import toast from 'react-hot-toast';
import type { AppThunk } from '~/store.ts';
import { getStorageBackend } from './selectors.ts';

export function eraseStorage(onCompleted?: () => void): AppThunk {
  return async (dispatch, getState) => {
    const storage = getStorageBackend(getState());
    const doErase = async () => {
      await storage.ensurePrepared();
      await storage.erase();
    };

    await toast.promise(doErase(), {
      loading: t('Storage.erase.loading'),
      success: t('Storage.erase.success'),
      error: t('Storage.erase.error'),
    });

    if (onCompleted) {
      onCompleted();
    }
  };
}
