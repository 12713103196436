import normal from '@stdlib/random-base-normal';

import type { SignalProcessingContext } from '../context/base.ts';
import type { ReadOptions } from '../types.ts';
import { SignalFilterBase } from './base.ts';

export interface NoiseNodeProps {
  variance?: number;
}

const zero = () => 0;

/**
 * Adds Gaussian noise to the input signal.
 */
export class NoiseNode extends SignalFilterBase {
  static readonly defaults: Readonly<Required<NoiseNodeProps>> = {
    variance: 0,
  };

  channelCount = 1;
  numberOfInputs = 1;
  numberOfOutputs = 1;

  _variance = 0;
  _rng: (() => number) | undefined = undefined;

  constructor(context: SignalProcessingContext, props: NoiseNodeProps = {}) {
    super(context);

    Object.assign(this, { ...NoiseNode.defaults, ...props });
  }

  get variance() {
    return this._variance;
  }

  set variance(value: number) {
    this._variance = value;
  }

  read(buffer: Float32Array, options: ReadOptions): number {
    const numSamples = this._readInput(buffer, 0, options);

    if (this._rng === undefined) {
      this._rng = this._variance > 0 ? normal.factory(0, this._variance) : zero;
    }

    if (this.variance > 0) {
      for (let i = 0; i < numSamples; i++) {
        buffer[i] += this._rng();
      }
    }

    return numSamples;
  }
}
