import CssBaseline from '@mui/material/CssBaseline';
import { t } from 'i18next';
import { ConfirmProvider } from 'material-ui-confirm';
import React from 'react';
import ReactDOM from 'react-dom/client';
import toast, { Toaster } from 'react-hot-toast';
import { Provider as StoreProvider } from 'react-redux';
import App from './App.tsx';
import LanguageWatcher from './features/settings/components/LanguageWatcher.tsx';
import { initI18N } from './i18n.ts';
import * as serviceWorkerRegistration from './service-worker-registration.ts';
import store from './store.ts';
import { LocalizedThemeProvider, toastOptions } from './theme.tsx';

await initI18N();

const rootElement = document.querySelector('#root');
const root = ReactDOM.createRoot(rootElement!);

root.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <LocalizedThemeProvider>
        <ConfirmProvider>
          <CssBaseline />
          <LanguageWatcher />
          <App />
          <Toaster toastOptions={toastOptions} />
        </ConfirmProvider>
      </LocalizedThemeProvider>
    </StoreProvider>
  </React.StrictMode>,
);

await serviceWorkerRegistration.register({
  onSuccess() {
    toast.success(t('PWA.ready'));
  },

  onUpdate() {
    toast(t('PWA.updateAvailable'), { icon: '✨' });
  },
});
