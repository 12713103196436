import { useCallback, useEffect, useState } from 'react';
import { getStorageBackend } from '~/features/storage/selectors.ts';
import { useAppSelector } from './store.ts';

const increase = (x: number) => x + 1;

/**
 * Hook that constructs and returns the current storage backend and forces a
 * re-render if the storage backend changes.
 */
export default function useStorage() {
  const storage = useAppSelector(getStorageBackend);
  const [revision, setRevision] = useState(0);
  const refresh = useCallback(() => {
    setRevision(increase);
  }, [setRevision]);
  useEffect(
    () =>
      storage.subscribe(() => {
        setRevision(increase);
      }),
    [storage],
  );
  return { storage, revision, refresh };
}
