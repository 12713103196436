import InputAdornment from '@mui/material/InputAdornment';
import TextField, { type TextFieldProps } from '@mui/material/TextField';
import toNumber from 'lodash-es/toNumber';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export type DurationFieldProps = Omit<TextFieldProps, 'onChange' | 'value'> & {
  onChange: (value: number) => void;
  value: number;
};

enum ValidationResult {
  OK = 0,
  NOT_A_NUMBER = 1,
  NEGATIVE = 2,
}

function formatValidationResult(
  t: (id: string) => string,
  result: ValidationResult,
) {
  switch (result) {
    case ValidationResult.OK: {
      return '';
    }

    case ValidationResult.NOT_A_NUMBER: {
      return t('Validation.notANumber');
    }

    case ValidationResult.NEGATIVE: {
      return t('Validation.negative');
    }

    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default: {
      return t('Validation.unknownError');
    }
  }
}

function validate(display: string): [number | undefined, ValidationResult] {
  display = display.trim();
  if (display === '') {
    display = '0';
  }

  const parsedValue = toNumber(display);
  if (!Number.isFinite(parsedValue)) {
    return [undefined, ValidationResult.NOT_A_NUMBER];
  }

  if (parsedValue < 0) {
    return [undefined, ValidationResult.NEGATIVE];
  }

  return [parsedValue, ValidationResult.OK];
}

const formatValue = String;

export default function DurationField({
  helperText,
  value,
  onChange,
  ...rest
}: DurationFieldProps) {
  const [validationResult, setValidationResult] = useState(ValidationResult.OK);
  const [displayedValue, setDisplayedValue] = useState(formatValue(value ?? 0));
  const hasError = validationResult !== ValidationResult.OK;
  const { t } = useTranslation();

  return (
    <TextField
      InputProps={{
        endAdornment: <InputAdornment position="end">sec</InputAdornment>,
      }}
      {...rest}
      error={hasError}
      helperText={
        hasError ? formatValidationResult(t, validationResult) : helperText
      }
      value={displayedValue}
      onChange={(event) => {
        const newValue = event.target.value;
        const [, error] = validate(newValue);
        setDisplayedValue(newValue);
        setValidationResult(error);
      }}
      onBlur={() => {
        const [parsed, error] = validate(displayedValue);
        if (error === ValidationResult.OK) {
          setDisplayedValue(formatValue(parsed!));
          onChange(parsed!);
        } else {
          setDisplayedValue(formatValue(value));
        }

        setValidationResult(ValidationResult.OK);
      }}
    />
  );
}
