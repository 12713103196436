import RecordIcon from '@mui/icons-material/FiberManualRecord';
import StopIcon from '@mui/icons-material/Stop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import LabeledSidebarText from '~/components/LabeledSidebarText.tsx';
import Sidebar from '~/components/Sidebar.tsx';
import { startRecording, stopRecording } from '~/features/recording/actions.ts';
import { isRecording } from '~/features/recording/slice.ts';
import { useAppDispatch, useAppSelector } from '~/hooks/store.ts';
import ElapsedTime from './ElapsedTime.tsx';
import EraseLastRecordingButton from './EraseLastRecordingButton.tsx';
import MetadataEditor from './MetadataEditor.tsx';

/**
 * Component showing the sidebar of the recording view.
 */
export default function RecordingSidebar() {
  const dispatch = useAppDispatch();
  const isRecordingInProgress = useAppSelector(isRecording);
  const { t } = useTranslation();

  return (
    <Sidebar disablePadding>
      <Box sx={{ flex: 1, overflowY: 'auto', overflowX: 'hidden' }}>
        <ElapsedTime />
        <hr />
        <LabeledSidebarText
          label={t('Sidebar.heartRate')}
          primaryColor="success.main"
          primaryText="62"
        />
        <hr />
        <MetadataEditor />
      </Box>
      <Stack direction="column" gap={2} padding={2}>
        <Stack direction="row" gap={2}>
          <Button
            startIcon={<RecordIcon />}
            variant="contained"
            color="error"
            fullWidth
            onClick={() => {
              dispatch(startRecording());
            }}
            disabled={isRecordingInProgress}
          >
            {t('Actions.startRecording.label')}
          </Button>
          <Button
            startIcon={<StopIcon />}
            variant="contained"
            color="warning"
            fullWidth
            onClick={() => {
              dispatch(stopRecording());
            }}
            disabled={!isRecordingInProgress}
          >
            {t('Actions.stopRecording.label')}
          </Button>
        </Stack>
        <EraseLastRecordingButton />
      </Stack>
    </Sidebar>
  );
}
