import type { RecordingUpdate } from '../types.ts';

/**
 * Returns an array in which the samples of the last batch are returned from a
 * recording update, interleaved in the order they appear in the list of
 * channels.
 *
 * For instance, if the last batch contains 4 samples per channel and there are
 * 3 channels (`x`, `y` and `z`), the result will be an array containing
 * `x[0], y[0], z[0], x[1], y[1], ..., y[3], z[3]`.
 *
 * @param update  the update received from the {@link Recording} object
 */
export function getInterleavedSamples(update: RecordingUpdate): Float32Array {
  const { bounds, buffers } = update;
  const [lo, hi] = bounds;
  const wrapped = hi < lo;
  const bufLength = update.getBufferLength();
  const numSamples = wrapped ? hi - lo + bufLength : hi - lo;
  const numBuffers = update.buffers.length;
  const result: Float32Array = new Float32Array(numBuffers * numSamples);
  let outIndex = 0;

  if (wrapped) {
    for (let index = lo; index < bufLength; index++) {
      for (let bufIndex = 0; bufIndex < numBuffers; bufIndex++) {
        result[outIndex++] = buffers[bufIndex][index];
      }
    }

    for (let index = 0; index < hi; index++) {
      for (let bufIndex = 0; bufIndex < numBuffers; bufIndex++) {
        result[outIndex++] = buffers[bufIndex][index];
      }
    }
  } else {
    for (let index = lo; index < hi; index++) {
      for (let bufIndex = 0; bufIndex < numBuffers; bufIndex++) {
        result[outIndex++] = buffers[bufIndex][index];
      }
    }
  }

  return result;
}
