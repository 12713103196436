import { useMountEffect, useUnmountEffect } from '@react-hookz/web';
import { useAppDispatch } from '~/hooks/store.ts';
import { startSampling, stopSampling } from '../actions.ts';

/**
 * Stores how many times the Monitoring component is mounted.
 */
let mountCounter = 0;

/**
 * React component that renders nothing but forces the recording subsystem to
 * sample the signal processing chain for display purposes as long as the
 * component remains mounted.
 */
export default function Monitoring() {
  const dispatch = useAppDispatch();

  useMountEffect(() => {
    mountCounter++;
    if (mountCounter > 0) {
      dispatch(startSampling());
    }
  });

  useUnmountEffect(() => {
    mountCounter--;
    if (mountCounter <= 0) {
      dispatch(stopSampling());
    }
  });

  return null;
}
