import { registerRecordingTargetType } from './factory.ts';
import type { RecordingTarget } from './types.ts';

/**
 * Specification of a dummy record target that does not record the samples
 * anywhere.
 */
export type DummyRecordingTargetSpec = { type: 'dummy' };

/**
 * Recording target that does nothing.
 */
export class DummyRecordingTarget implements RecordingTarget {
  async close() {
    /* empty */
  }

  async prepare() {
    /* empty */
  }

  store() {
    /* empty */
  }
}

export default function createDummyRecordingTarget() {
  return new DummyRecordingTarget();
}

const disposer = registerRecordingTargetType(
  'dummy',
  createDummyRecordingTarget,
);
import.meta.webpackHot?.dispose(disposer);
