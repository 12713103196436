import type { ReadOptions } from '../types.ts';
import { SignalSinkBase } from './base.ts';

/**
 * Interface specification for objects that can act as signal sinks.
 */
export class BufferedSignalSink extends SignalSinkBase {
  channelCount = 1;
  numberOfInputs = 1;
  numberOfOutputs = 0;

  read(buffer: Float32Array, options?: ReadOptions): number {
    const conn = this._inputs[0];
    return conn
      ? conn.source.node.read(buffer, {
          ...options,
          output: conn.source.index,
        })
      : 0;
  }
}
