import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export type LabeledSidebarTextProps = {
  labelColor?: string;
  label?: string;
  primaryColor?: string;
  primaryText: string;
};

export default function LabeledSidebarText({
  labelColor = 'text.disabled',
  label,
  primaryColor = 'primary.main',
  primaryText,
}: LabeledSidebarTextProps) {
  return (
    <Box sx={{ px: 2, my: 2 }}>
      {label && (
        <Typography align="right" variant="body1" color={labelColor}>
          {label}
        </Typography>
      )}
      <Typography
        align="center"
        color={primaryColor}
        sx={{ fontSize: '400%', fontVariantNumeric: 'tabular-nums' }}
      >
        {primaryText}
      </Typography>
    </Box>
  );
}
