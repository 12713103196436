import Tab from '@mui/material/Tab';
import Tabs, { type TabsProps } from '@mui/material/Tabs';
import type { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { MainWindowTab } from './types.ts';

export type MainWindowTabsProps = TabsProps;

const tabs: MainWindowTab[] = [
  MainWindowTab.RECORD,
  MainWindowTab.ANALYSE,
  MainWindowTab.STORAGE,
  MainWindowTab.SETTINGS,
];

const getLabelForTab = (tab: MainWindowTab, t: TFunction): string => {
  switch (tab) {
    case MainWindowTab.RECORD: {
      return t('MainWindowTab.record.label');
    }

    case MainWindowTab.ANALYSE: {
      return t('MainWindowTab.analyse.label');
    }

    case MainWindowTab.STORAGE: {
      return t('MainWindowTab.storage.label');
    }

    case MainWindowTab.SETTINGS: {
      return t('MainWindowTab.settings.label');
    }
  }

  return t('MainWindowTab.unknown.label', { tab });
};

export default function MainWindowTabs(props: TabsProps) {
  const { t } = useTranslation();

  return (
    <Tabs centered sx={{ flex: 1, my: 2 }} {...props}>
      {tabs.map((tab) => (
        <Tab key={tab} value={tab} label={getLabelForTab(tab, t)} />
      ))}
    </Tabs>
  );
}
