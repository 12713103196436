import {
  configureStore,
  type ThunkAction,
  type UnknownAction,
} from '@reduxjs/toolkit';
import mainWindow from './features/main-window/slice.ts';
import recording, { _updateRecording } from './features/recording/slice.ts';
import settings from './features/settings/slice.ts';
import storage from './features/storage/slice.ts';
import { listenerMiddleware } from './listeners.ts';

/**
 * State store of the entire application.
 */
const store = configureStore({
  reducer: {
    mainWindow,
    recording,
    settings,
    storage,
  },

  devTools: {
    actionsDenylist: [_updateRecording.toString()],
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(listenerMiddleware.middleware),
});

/** Inferred type of the state store of the application */
export type AppStore = typeof store;

/** Inferred root state of the application */
export type AppState = ReturnType<typeof store.getState>;

/** Inferred dispatcher function of the state store */
export type AppDispatch = typeof store.dispatch;

/** Inferred type of thunks that can be dispatched to the state store */
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  UnknownAction
>;

export default store;
