import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { MainWindowTab } from './types.ts';

export interface MainWindowState {
  selectedTab: MainWindowTab;
  sidebarWidthPercentage: number;
}

const initialState: MainWindowState = {
  selectedTab: MainWindowTab.RECORD,
  sidebarWidthPercentage: 20,
};

export const mainWindowSlice = createSlice({
  name: 'mainWindow',
  initialState,

  reducers: {
    setSelectedTab(state, { payload }: PayloadAction<MainWindowTab>) {
      state.selectedTab = payload;
    },
    setSidebarWidthPercentage(state, { payload }: PayloadAction<number>) {
      state.sidebarWidthPercentage = payload;
    },
  },

  selectors: {
    getSelectedTab: (state) => state.selectedTab,
    getSidebarWidthPercentage: (state) => state.sidebarWidthPercentage,
  },
});

export const { setSelectedTab, setSidebarWidthPercentage } =
  mainWindowSlice.actions;
export const { getSelectedTab, getSidebarWidthPercentage } =
  mainWindowSlice.selectors;

export default mainWindowSlice.reducer;
