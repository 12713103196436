import sync, { cancelSync, type FrameData, type Process } from 'framesync';
import { normalizedPulseWave } from '~/data/pulse-wave.ts';
import type { AppStartListening, ListenerSetupFn } from '~/listeners.ts';
import { StreamingSignalProcessingContext } from '~/model/context/index.ts';
import type { SignalSink } from '~/model/destinations/base.ts';
import { GainNode, NoiseNode } from '~/model/filters/index.ts';
import { LoopedSource } from '~/model/sources/looped.ts';
import { decimate } from '~/utils/math.ts';
import { updateRecording } from './actions.ts';
import { getRecordingChannelIds } from './selectors.ts';
import { _startSamplingTask, _stopSamplingTask } from './slice.ts';
import type { RecordingUpdate } from './types.ts';

interface RecordingOptions {
  /** Number of samples in the recording buffer */
  bufferLength: number;

  /** Identifiers of the channels that the recording targets */
  channelIds: string[];

  /** Number of samples per second */
  sampleRate: number;
}

class Recording {
  /** The sample rate of the recording */
  private readonly _sampleRate: number;

  /**
   * The length of each internal buffer in which the samples are recorded for
   * displaying purposes.
   *
   * The buffer is treated as a circular buffer; old samples are overwritten
   * with new ones, wrapping around at the buffer boundary.
   */
  private readonly _bufferLength: number;

  /**
   * The internal buffers in which the samples are recorded for displaying
   * purposes. Internally, it is twice as long as {@link _bufferLength} to
   * allow reading the next chunk into a continuous memory segment before
   * the buffer wraps over.
   */
  private readonly _buffers: Float32Array[];

  /**
   * Identifiers of the channels being recorded.
   */
  private readonly _channelIds: string[];

  /**
   * Bounds within which the last batch of samples were written. The first index
   * is the start index (inclusive), the second index is the end index
   * (exclusive). The second index may be smaller than the first when the
   * recording buffer wrapped around.
   */
  private readonly _lastWriteBounds: [number, number];

  /**
   * Total number of samples read so far. Used to determine how many samples to
   * read given the current time and the sample rate.
   */
  private _numSamplesRead = 0;

  /**
   * High-precision timestamp storing the start time of the recording.
   */
  private _startedAt = 0;

  /**
   * The underlying background task of the recording; null if the recording
   * task is not running.
   */
  private _task: Process | null = null;

  /**
   * Index where the next samples will be written in the buffers. It is assumed
   * that buffers are filled perfectly in sync.
   */
  private _writeIndex: number;

  constructor(options: RecordingOptions) {
    const { bufferLength, channelIds, sampleRate } = options;

    this._bufferLength = bufferLength;
    this._channelIds = channelIds;
    this._sampleRate = sampleRate;

    this._buffers = channelIds.map(() => new Float32Array(bufferLength * 2));
    this._writeIndex = 0;

    this._lastWriteBounds = [0, 0];
  }

  /**
   * The number of channels being recorded.
   */
  get channelCount(): number {
    return this._channelIds.length;
  }

  /**
   * Starts the recording of the destination ndoe of the given signal processing
   * context.
   *
   * @param context the context to record
   * @param onUpdated a function to call when a batch of samples was received.
   *        The function is called with a single {@link RecordingUpdate} object
   *        that is re-used between calls.
   */
  start(
    context: StreamingSignalProcessingContext,
    onUpdated: (update: RecordingUpdate) => void,
  ): void {
    if (this._task) {
      /* Already recording */
      return;
    }

    this._startedAt = 0;
    this._numSamplesRead = 0;

    const buffers = this._buffers.map((buffer) =>
      buffer.subarray(0, this._bufferLength),
    );
    const bufferMap: Record<string, Float32Array> = Object.fromEntries(
      this._buffers.map((buffer: Float32Array, index: number) => [
        this._channelIds[index],
        buffers[index],
      ]),
    );
    const update: RecordingUpdate = {
      bounds: this._lastWriteBounds,
      bufferMap,
      buffers,

      getPlayheadPosition: () => this._writeIndex,
      getBufferLength: () => this._bufferLength,
    };

    this._task = sync.update(
      this._execute.bind(this, context, () => {
        onUpdated(update);
      }),
      true,
    );
  }

  /**
   * Stops the current recording.
   */
  stop(): void {
    if (this._task) {
      cancelSync.update(this._task);
      this._startedAt = 0;
      this._task = null;
    }
  }

  _execute(
    context: StreamingSignalProcessingContext,
    onUpdated: () => void,
    { timestamp }: FrameData,
  ) {
    if (this._startedAt === 0) {
      this._startedAt = timestamp;
      return;
    }

    const timeDiff = timestamp - this._startedAt;
    const samplesNeeded = Math.floor((timeDiff * this._sampleRate) / 1000);
    const samplesToRead = samplesNeeded - this._numSamplesRead;

    if (samplesToRead > 0) {
      const samplesReadNow = this._fillBufferFromSink(
        0,
        context.destination,
        samplesToRead,
      );
      this._numSamplesRead += samplesReadNow;
    }

    onUpdated();
  }

  /**
   * Fills the buffer with the given index with the given number of samples from
   * a signal processing sink.
   *
   * @param index       the index of the buffer to fill
   * @param sink        the sink to read from
   * @param numSamples  maximum number of samples to read from the sink
   * @returns the number of samples that were read from the sink
   */
  _fillBufferFromSink(index: number, sink: SignalSink, numSamples: number) {
    const buffer = this._buffers[index];
    let samplesRead = 0;

    while (numSamples > 0) {
      const toRead = Math.min(numSamples, this._bufferLength);
      const samplesReadNow = sink.read(
        buffer.subarray(this._writeIndex, this._writeIndex + toRead),
      );
      if (samplesReadNow === 0) {
        break;
      }

      numSamples -= samplesReadNow;
      samplesRead += samplesReadNow;

      let newWriteIndex = this._writeIndex + samplesReadNow;
      if (newWriteIndex >= this._bufferLength) {
        // Buffer wrapped around
        buffer.copyWithin(0, this._bufferLength, newWriteIndex);
        newWriteIndex -= this._bufferLength;
      }

      this._lastWriteBounds[0] = this._writeIndex;
      this._lastWriteBounds[1] = newWriteIndex;

      this._writeIndex = newWriteIndex;
    }

    return samplesRead;
  }
}

const createContext = () => {
  const context = new StreamingSignalProcessingContext({ sampleRate: 100 });
  const source = new LoopedSource(context, {
    values: Float32Array.from(decimate(normalizedPulseWave, 4)),
  });
  const gain = new GainNode(context, { gain: 0.8 });
  const noise = new NoiseNode(context, { variance: 0.02 });

  source.connect(noise).connect(gain).connect(context.destination);

  return context;
};

export const addRecordingListeners: ListenerSetupFn = (
  startListening: AppStartListening,
) => {
  let activeRecording: Recording | null = null;

  startListening({
    actionCreator: _startSamplingTask,
    async effect(action, { dispatch, getState }) {
      const channelIds = getRecordingChannelIds(getState());
      activeRecording ||= new Recording({
        bufferLength: 500,
        channelIds,
        sampleRate: 100,
      });
      activeRecording.start(createContext(), (update) => {
        dispatch(updateRecording(update));
      });
    },
  });

  startListening({
    actionCreator: _stopSamplingTask,
    effect(action, listenerApi) {
      if (activeRecording) {
        activeRecording.stop();
        activeRecording = null;
      }
    },
  });
};
