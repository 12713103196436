import type { RecordingMetadata } from '~/model/metadata.ts';
import type { RecordingUpdate } from '../types.ts';
import { registerRecordingTargetType } from './factory.ts';
import type { RecordingTarget } from './types.ts';
import { getInterleavedSamples } from './utils.ts';

/**
 * Specification of a record target that dumps the raw samples to the debug
 * console.
 */
export type DebugRecordingTargetSpec = { type: 'debug' };

/**
 * Recording target that dumps the interleaved samples from the last batch into
 * the console.
 */
export class DebugRecordingTarget implements RecordingTarget {
  async close() {
    /* empty */
  }

  async prepare(metadata: RecordingMetadata) {
    console.log(metadata);
  }

  store(update: RecordingUpdate) {
    console.log(getInterleavedSamples(update));
  }
}

export default function createDebugRecordingTarget() {
  return new DebugRecordingTarget();
}

const disposer = registerRecordingTargetType(
  'debug',
  createDebugRecordingTarget,
);
import.meta.webpackHot?.dispose(disposer);
