/*
 * Generated type guards for "metadata.ts".
 * WARNING: Do not manually change this file.
 */
import { RecordingMetadata } from './metadata';

export function isRecordingMetadata(obj: unknown): obj is RecordingMetadata {
  const typedObj = obj as RecordingMetadata;
  return (
    ((typedObj !== null && typeof typedObj === 'object') ||
      typeof typedObj === 'function') &&
    typeof typedObj['patientId'] === 'string' &&
    typeof typedObj['index'] === 'string' &&
    (typedObj['stopCondition'] === 'manual' ||
      typedObj['stopCondition'] === 'fixedDuration') &&
    typeof typedObj['duration'] === 'number' &&
    typeof typedObj['sampleRate'] === 'number' &&
    typeof typedObj['startedAt'] === 'number'
  );
}
