import { useTheme, type SxProps } from '@mui/material';
import Box from '@mui/material/Box';

export type TopLevelViewWrapperProps = React.PropsWithChildren & {
  padding?: number | boolean;
};

/**
 * Wrapper component for top-level views of the application.
 *
 * @param props - the props of the component
 */
export default function TopLevelViewWrapper({
  children,
  padding = 0,
}: TopLevelViewWrapperProps) {
  const theme = useTheme();
  const sx: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
    position: 'relative',
    overflowY: 'hidden',
  };

  if (typeof padding === 'boolean') {
    padding = padding ? 2 : 0;
  }

  if (padding > 0) {
    sx.padding = theme.spacing(padding);
  }

  return <Box sx={sx}>{children}</Box>;
}
