import { createSlice } from '@reduxjs/toolkit';
import type { StorageBackendSpec } from '~/model/storage/types.ts';

export interface StorageState {
  /** Stores the specification of the current storage backend */
  backend: StorageBackendSpec;
}

/** Initial state of the storage slice of the state store after startup. */
const initialState: StorageState = {
  backend: {
    type: 'opfs',
  },
};

export const storageSlice = createSlice({
  name: 'storage',
  initialState,

  reducers: {},

  selectors: {
    getStorageBackendSpec: (state) => state.backend,
  },
});

/*
export const {
    getStorageBackendSpec,
} = storageSlice.actions;
*/

export const { getStorageBackendSpec } = storageSlice.selectors;

export default storageSlice.reducer;
