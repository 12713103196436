import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import {
  DataGrid,
  GridActionsCellItem,
  type GridColDef,
} from '@mui/x-data-grid';
import type { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import { useAsync } from '@react-hookz/web';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useStorage from '~/hooks/useStorage.ts';
import type { RecordingMetadata } from '~/model/metadata.ts';

const initialState: GridInitialStateCommunity = {
  sorting: {
    sortModel: [{ field: 'startedAt', sort: 'desc' }],
  },
};

type Translator = (key: string) => string;

const createColumnDefinitions = (t: Translator): GridColDef[] => [
  {
    field: 'recordingId',
    headerName: t('StorageDataGrid.columns.recordingId'),
    valueGetter(_, row: RecordingMetadata) {
      const { patientId, index } = row;
      return patientId ? `${patientId}/${index}` : String(index);
    },
  },
  {
    field: 'startedAt',
    headerName: t('StorageDataGrid.columns.startedAt'),
    type: 'dateTime',
    flex: 1,
    valueGetter: (value) => new Date(value),
  },
  {
    field: 'actions',
    headerName: t('StorageDataGrid.columns.actions'),
    type: 'actions',
    getActions: () => [
      <GridActionsCellItem
        icon={<DeleteIcon />}
        label={t('Actions.delete')}
        disabled
      />,
      <GridActionsCellItem
        icon={<DownloadIcon />}
        label={t('Actions.download')}
        disabled
      />,
    ],
  },
];

export default function StorageDataGrid() {
  const { storage, revision } = useStorage();
  const { t } = useTranslation();
  const columns = useMemo(() => createColumnDefinitions(t), [t]);

  const [{ status, result }, { reset, execute }] = useAsync(async () => {
    const metadata = await storage.listRecordings();
    return metadata;
  });

  useEffect(() => {
    reset();
    void execute();
  }, [revision, execute, reset]);

  return (
    <DataGrid
      checkboxSelection
      initialState={initialState}
      rows={result}
      columns={columns}
      loading={status === 'loading'}
      slotProps={{
        loadingOverlay: {
          variant: 'skeleton',
          noRowsVariant: 'skeleton',
        },
      }}
      sx={{ border: 0 }}
    />
  );
}
