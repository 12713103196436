import { t } from 'i18next';
import mapValues from 'lodash-es/mapValues';
import pAll from 'p-all';
import toast from 'react-hot-toast';
import type { AppThunk } from '~/store.ts';
import { getActiveRecordingTargets, getRecordingTargets } from './selectors.ts';
import {
  _setRecording,
  _setSampling,
  _startSamplingTask,
  _stopSamplingTask,
  _updateRecording,
  addChannels,
  addRecordingTargets,
  getLastRecordingId,
  getMetadata,
  isRecording,
  isSampling,
  removeAllChannels,
  removeAllRecordingTargets,
  setLastRecordingId,
  updateMetadata,
  type Channel,
} from './slice.ts';
import type { RecordingUpdate } from './types.ts';

import { shouldIncreaseRecordingIndexAutomatically } from '~/features/settings/slice.ts';
import type { RecordingId } from '~/model/storage/types.ts';
import { getStorageBackend } from '../storage/selectors.ts';
import { StorageRecordingTarget } from './targets/storage.ts';

/**
 * Creates an action that prepares the given number of channels in the state of
 * the recording subsystem when dispatched to the store.
 */
const prepareChannels: (numChannels: number) => AppThunk =
  () => async (dispatch) => {
    dispatch(removeAllChannels());

    const names = ['A', 'B', 'C', 'D'];
    const channels = names.map(
      (name, index): Channel => ({
        id: name,
        label: name,
        index,
        samples: new Array<number>(500).fill(0),
      }),
    );

    dispatch(addChannels(channels));
  };

/**
 * Creates an action that starts sampling the signal source when dispatched to the store.
 */
export const startSampling: () => AppThunk = () => (dispatch) => {
  dispatch(_setSampling(true));
  dispatch(prepareChannels(4));
  dispatch(_startSamplingTask());
};

/**
 * Creates an action that stops sampling the signal source when dispatched to the store.
 */
export const stopSampling: () => AppThunk = () => (dispatch) => {
  dispatch(_stopSamplingTask());
  dispatch(_setSampling(false));
};

/**
 * Creates an action that starts recording when dispatched to the store.
 */
export const startRecording: () => AppThunk =
  () => async (dispatch, getState) => {
    const isSamplingInProgress = isSampling(getState());
    if (!isSamplingInProgress) {
      /* We can start recording only if sampling is in progress */
      toast.error(
        t('Actions.startRecording.errors.cannotStartWithoutSampling'),
      );
      return;
    }

    dispatch(addRecordingTargets([{ type: 'storage', id: 'storage' }]));
    dispatch(updateMetadata({ startedAt: Date.now() }));

    const targets = getRecordingTargets(getState());
    const metadata = getMetadata(getState());
    const prepareAll = Promise.all(
      targets.map(async (target) => target.prepare(metadata)),
    );

    try {
      await toast.promise(prepareAll, {
        loading: t('Storage.prepare.loading'),
        success: t('Storage.prepare.success'),
        error: t('Storage.prepare.error'),
      });
    } catch {
      return;
    }

    dispatch(_setRecording(true));
  };

/**
 * Creates an action that stop recording when dispatched to the store.
 */
export const stopRecording: () => AppThunk =
  () => async (dispatch, getState) => {
    const state = getState();
    const wasRecording = isRecording(state);

    dispatch(_setRecording(false));

    const targets = getRecordingTargets(getState());
    try {
      await pAll(
        targets.map((target) => target.close.bind(target)),
        { stopOnError: false },
      );
    } catch (error) {
      const count = error instanceof AggregateError ? error.errors.length : 1;
      toast.error(
        t('Actions.stopRecording.errors.failedToCloseCleanly', { count }),
      );

      if (error instanceof AggregateError) {
        for (const subError of error.errors) {
          console.error(subError);
        }
      } else {
        console.error(error);
      }
    }

    dispatch(removeAllRecordingTargets());

    if (wasRecording && shouldIncreaseRecordingIndexAutomatically(state)) {
      dispatch(increaseRecordingIndex());
    }

    for (const target of targets) {
      if (target instanceof StorageRecordingTarget) {
        dispatch(setLastRecordingId(target.getLastRecordingId() ?? null));
      }
    }
  };

export const updateRecording: (update: RecordingUpdate) => AppThunk =
  (update) => async (dispatch, getState) => {
    const targets = getActiveRecordingTargets(getState());
    for (const target of targets) {
      target.store(update);
    }

    dispatch(
      _updateRecording({
        playheadPosition: update.getPlayheadPosition(),
        buffers: mapValues(update.bufferMap, (buffer) => Array.from(buffer)),
      }),
    );
  };

/**
 * Increases the recording index in the metadata of the recording.
 */
export const increaseRecordingIndex: () => AppThunk =
  () => (dispatch, getState) => {
    /* Parse the longest suffix consisting of numbers only, increase the number
     * and then replace the suffix with the new number */
    const { index } = getMetadata(getState());
    const regex = /^(?<prefix>.*)(?<suffix>\d+)$/;
    const match = regex.exec(index);
    let newIndex;

    if (match) {
      const parsedSuffix = Number.parseInt(match.groups!.suffix, 10);
      newIndex = match.groups!.prefix + String(parsedSuffix + 1);
    } else {
      newIndex = index + '1';
    }

    dispatch(updateMetadata({ index: newIndex }));
  };

/**
 * Erases the recording with the given ID from the storage backend.
 */
export const eraseRecordingById: (id: RecordingId) => AppThunk =
  (id: RecordingId) => async (dispatch, getState) => {
    const backend = getStorageBackend(getState());
    await toast.promise(backend.removeRecordingById(id), {
      loading: t('Recording.erase.loading'),
      success: t('Recording.erase.success'),
      error: t('Recording.erase.error'),
    });
  };

/**
 * Erases the recording from the storage backend that was recorded for the
 * last time.
 */
export const eraseLastRecording: () => AppThunk =
  () => (dispatch, getState) => {
    const lastRecordingId = getLastRecordingId(getState());
    if (lastRecordingId) {
      dispatch(eraseRecordingById(lastRecordingId));
      dispatch(setLastRecordingId(null));
    }
  };
