import type { StorageBackendFactory, StorageBackendSpec } from './types.ts';

/**
 * Registry that maps storage backend specifications to the functions that
 * create the corresponding storage backends.
 */
const _registry: Record<string, StorageBackendFactory> = {};

/**
 * Creates a storage backend object from its specification.
 */
export function createStorageBackend(spec: StorageBackendSpec) {
  const { type } = spec;
  const factory = _registry[type];
  if (factory) {
    return factory(spec);
  }

  throw new Error(`Unknown storage backend type: ${type}`);
}

/**
 * Registers a storage backend type.
 *
 * @param type the type to register
 * @param factory the factory function to call when a target with this type
 *        needs to be constructed
 *
 * @returns a disposer function that can be called to unregister the type
 */
export function registerStorageBackendType(
  type: string,
  factory: StorageBackendFactory,
): () => void {
  if (_registry[type]) {
    throw new Error(`Storage backend type is already registered: ${type}`);
  }

  _registry[type] = factory;

  return () => {
    unregisterStorageBackendType(type);
  };
}

/**
 * Unregisters a storage backend type.
 *
 * @param type the type to unregister
 */
export function unregisterStorageBackendType(type: string) {
  delete _registry[type]; // eslint-disable-line @typescript-eslint/no-dynamic-delete
}
