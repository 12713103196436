import {
  SignalProcessingNodeBase,
  type SignalProcessingNode,
} from '../signals.ts';
import type { ReadOptions } from '../types.ts';

/**
 * Interface specification for objects that can act as signal filters.
 */
export interface SignalFilter extends SignalProcessingNode {}

export abstract class SignalFilterBase
  extends SignalProcessingNodeBase
  implements SignalFilter
{
  /**
   * Helper function to read the samples from an input connection with a
   * given index.
   *
   * @param buffer  the buffer to read the samples to
   * @param index   the index of the input connection to read
   * @returns  the number of samples that were read
   */
  protected _readInput(
    buffer: Float32Array,
    index = 0,
    options: Omit<ReadOptions, 'output'> = {},
  ): number {
    const conn = this._inputs[index];
    if (!conn) {
      return 0;
    }

    return conn.source.node.read(buffer, {
      ...options,
      output: conn.source.index,
    });
  }
}
