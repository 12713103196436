import { createSlice, type PayloadAction, type Update } from '@reduxjs/toolkit';
import type { RecordingMetadata } from '~/model/metadata.ts';
import type { RecordingId } from '~/model/storage/types.ts';
import { channelAdapter, targetAdapter } from './adapters.ts';
import type { RecordingTargetSpecWithId } from './types.ts';

export interface Channel {
  id: string;
  index: number;
  label: string;
  samples: number[];
}

export interface RecordingState {
  /** Stores whether we are currently recording samples */
  isRecording: boolean;

  /** Stores whether we are currently storing samples for visualization purposes */
  isSampling: boolean;

  /** Stores the ID of the last recording that was saved by the user */
  lastRecordingId: RecordingId | null;

  /**
   * Channels where the recorded samples are stored for visualization purposes
   * in the recording view.
   */
  channels: ReturnType<typeof channelAdapter.getInitialState>;

  /**
   * Metadata of the current recording.
   */
  metadata: RecordingMetadata;

  /**
   * Position of the playhead in the internal sample buffer where the next
   * batch of samples will be stored.
   */
  playheadPosition: number;

  /**
   * Specifications of the objects that handle storing the samples during
   * recording.
   */
  targets: ReturnType<typeof targetAdapter.getInitialState>;
}

/** Initial state of the recording slice of the state store after startup. */
const initialState: RecordingState = {
  isRecording: false,
  isSampling: false,

  lastRecordingId: null,

  channels: channelAdapter.getInitialState(),
  metadata: {
    patientId: '',
    index: '1',
    duration: 0,
    stopCondition: 'manual',
    sampleRate: 200,
    startedAt: 0,
  },
  playheadPosition: 0,
  targets: targetAdapter.getInitialState(),
};

export const recordingSlice = createSlice({
  name: 'recording',
  initialState,

  reducers: {
    addChannels(state, action: PayloadAction<Channel[]>) {
      channelAdapter.addMany(state.channels, action);
    },
    removeAllChannels(state) {
      channelAdapter.removeAll(state.channels);
    },

    addRecordingTargets(
      state,
      action: PayloadAction<RecordingTargetSpecWithId[]>,
    ) {
      targetAdapter.addMany(state.targets, action);
    },
    removeAllRecordingTargets(state) {
      targetAdapter.removeAll(state.targets);
    },

    setLastRecordingId(state, action: PayloadAction<RecordingId | null>) {
      state.lastRecordingId = action.payload;
    },

    updateMetadata(state, action: PayloadAction<Partial<RecordingMetadata>>) {
      state.metadata = { ...state.metadata, ...action.payload };
    },

    _setRecording(state, action: PayloadAction<boolean>) {
      state.isRecording = Boolean(action.payload);
    },
    _setSampling(state, action: PayloadAction<boolean>) {
      state.isSampling = Boolean(action.payload);
    },
    _startSamplingTask() {
      /* empty */
    },
    _stopSamplingTask() {
      /* empty */
    },
    _updateRecording(
      state,
      action: PayloadAction<{
        playheadPosition: number;
        buffers: Record<string, number[]>;
      }>,
    ) {
      const { buffers, playheadPosition } = action.payload;
      const updates: Array<Update<Channel, string>> = Object.entries(
        buffers,
      ).map(([id, samples]) => ({
        id,
        changes: { samples },
      }));

      state.playheadPosition = playheadPosition;
      channelAdapter.updateMany(state.channels, updates);
    },
  },

  selectors: {
    getLastRecordingId: (state) => state.lastRecordingId,
    getMetadata: (state) => state.metadata,
    getPlayheadPosition: (state) => state.playheadPosition,
    getRecordingStartTime: (state) =>
      state.isRecording ? state.metadata.startedAt : undefined,
    isRecording: (state) => state.isRecording,
    isSampling: (state) => state.isSampling,
  },
});

export const {
  addChannels,
  addRecordingTargets,
  removeAllChannels,
  removeAllRecordingTargets,
  setLastRecordingId,
  updateMetadata,
  _setRecording,
  _setSampling,
  _startSamplingTask,
  _stopSamplingTask,
  _updateRecording,
} = recordingSlice.actions;

export const {
  getLastRecordingId,
  getMetadata,
  getPlayheadPosition,
  getRecordingStartTime,
  isRecording,
  isSampling,
} = recordingSlice.selectors;

export default recordingSlice.reducer;
