import type { SignalProcessingContext } from '../context/base.ts';
import type { ReadOptions } from '../types.ts';
import { SignalSourceBase, type SignalSource } from './base.ts';

/**
 * Props of the looped signal source.
 */
export interface LoopedSourceProps {
  /** The sequence of values yielded by the signal source. */
  values: Float32Array;
}

const zeroArray: Float32Array = new Float32Array([0]);

/**
 * Signal source that provides a pre-recorded signal that is looped over and over.
 */
export class LoopedSource extends SignalSourceBase implements SignalSource {
  /** Default values of the props of the signal source */
  static readonly defaults: Readonly<LoopedSourceProps> = {
    values: zeroArray,
  };

  /** The values provided by the signal source */
  public values: Float32Array;

  channelCount = 1;
  numberOfOutputs = 1;

  /** Index of the next element to return from the {@link values} array */
  private _readIndex = 0;

  /**
   * Constructor.
   *
   * @param context - the signal processing context that the source belongs to
   * @param props - the properties of the signal source
   */
  constructor(
    context: SignalProcessingContext,
    props: Partial<LoopedSourceProps> = {},
  ) {
    super(context);

    const effectiveProps: LoopedSourceProps = {
      ...LoopedSource.defaults,
      ...props,
    };

    this.values = effectiveProps.values;
  }

  read(buffer: Float32Array, options: ReadOptions): number {
    const numValues = this.values.length;
    const bufferLength = buffer.length;
    let offset = 0;

    if (numValues === 0) {
      return 0;
    }

    // Fix up this._readIndex in case the values array became shorter
    this._readIndex %= numValues;

    while (offset < bufferLength) {
      const subarray = this.values.subarray(
        this._readIndex,
        this._readIndex + bufferLength - offset,
      );
      buffer.set(subarray, offset);

      offset += subarray.length;
      this._readIndex += subarray.length;
      while (this._readIndex >= numValues) {
        this._readIndex -= numValues;
      }
    }

    return buffer.length;
  }
}
