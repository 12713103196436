import type { SignalSink } from '../destinations/base.ts';
import { BufferedSignalSink } from '../destinations/buffered.ts';
import type { SignalProcessingContext } from './base.ts';

export interface StreamingSignalProcessingContextProps {
  sampleRate: number;
}

export class StreamingSignalProcessingContext
  implements SignalProcessingContext
{
  readonly destination: SignalSink;
  readonly sampleRate: number;

  constructor(props: StreamingSignalProcessingContextProps) {
    this.destination = new BufferedSignalSink(this);
    this.sampleRate = props.sampleRate;
  }
}

export default StreamingSignalProcessingContext;
