/**
 * Decimates an array by taking every n-th element.
 *
 * @param values - the input array
 * @param k - the downsampling factor
 * @returns another array that contains every n-th element from the input array
 */
export function decimate(values: readonly number[], k: number) {
  const n = values.length;
  if (k < 1 || !Number.isInteger(k)) {
    throw new Error('k must be a positive integer');
  }

  return new Array<number>(Math.floor(n / k))
    .fill(0)
    .map((_, index) => values[index * k]);
}

/**
 * Normalizes the values of a numeric array into a specified range.
 *
 * @param values - the input array to normalize
 * @param options - specifies the minimum and maximum values of the normalized array
 * @returns the normalized array
 */
export function normalize(
  values: readonly number[],
  options: { min?: number; max?: number } = {},
) {
  const { min = 0, max = 1 } = options;
  const minInput = Math.min(...values);
  const maxInput = Math.max(...values);
  const ratio = (max - min) / (maxInput - minInput);
  return values.map((value) => (value - minInput) * ratio + min);
}
