import TopLevelViewWrapper from '~/components/TopLevelViewWrapper.tsx';
import SidebarSplitter from '~/features/main-window/SidebarSplitter.tsx';
import Monitoring from '~/features/recording/components/Monitoring.tsx';
import Channels from './Channels.tsx';
import RecordingSidebar from './RecordingSidebar.tsx';

export default function RecordTab() {
  return (
    <TopLevelViewWrapper>
      <SidebarSplitter>
        <Channels />
        <RecordingSidebar />
      </SidebarSplitter>
      <Monitoring />
    </TopLevelViewWrapper>
  );
}
