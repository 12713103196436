import {
  SignalProcessingNodeBase,
  type SignalProcessingNode,
} from '../signals.ts';
import type { ReadOptions } from '../types.ts';

/**
 * Interface specification for objects that can act as signal sinks.
 */
export interface SignalSink extends SignalProcessingNode {
  read(buffer: Float32Array, options?: ReadOptions): number;
}

/**
 * Interface specification for objects that can act as signal sinks.
 */
export abstract class SignalSinkBase
  extends SignalProcessingNodeBase
  implements SignalSink
{
  numberOfOutputs = 0;
}
