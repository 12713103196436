import Delete from '@mui/icons-material/Delete';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useConfirm } from 'material-ui-confirm';
import { useTranslation } from 'react-i18next';
import { eraseStorage } from '~/features/storage/actions.ts';
import { useAppDispatch } from '~/hooks/store.ts';

/**
 * Component showing the sidebar of the recording view.
 */
export default function StorageSidebar() {
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const { t } = useTranslation();

  const handleClick = () => {
    confirm({ description: t('Actions.eraseStorage.confirmation') })
      .then(() => {
        dispatch(eraseStorage());
      })
      .catch(() => {
        /* nop */
      });
  };

  return (
    <ListItem disablePadding>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <Delete color="error" />
        </ListItemIcon>
        <ListItemText primary={t('Actions.eraseStorage.label')} />
      </ListItemButton>
    </ListItem>
  );
}
