import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { eraseLastRecording } from '~/features/recording/actions.ts';
import { getLastRecordingId } from '~/features/recording/slice.ts';
import { useAppDispatch, useAppSelector } from '~/hooks/store.ts';

export default function EraseLastRecordingButton() {
  const { t } = useTranslation();
  const lastRecordingId = useAppSelector(getLastRecordingId);
  const dispatch = useAppDispatch();

  return (
    <Button
      startIcon={<DeleteIcon />}
      variant="contained"
      color="error"
      disabled={!lastRecordingId}
      onClick={() => {
        dispatch(eraseLastRecording());
      }}
    >
      {t('Actions.eraseLastRecording.label')}
    </Button>
  );
}
