import type { RecordingMetadata } from '~/model/metadata.ts';
import { OpfsStorageBackend } from '~/model/storage/opfs.ts';
import type {
  RecordingId,
  RecordingWriter,
  StorageBackend,
} from '~/model/storage/types.ts';
import type { RecordingUpdate } from '../types.ts';
import { registerRecordingTargetType } from './factory.ts';
import type { RecordingTarget } from './types.ts';
import { getInterleavedSamples } from './utils.ts';

/**
 * Specification of a record target that writes the raw samples to a recording
 * in the storage backend.
 */
export type StorageRecordingTargetSpec = { type: 'storage' };

class RecordingBuffer {
  private readonly _data: Float32Array;
  private _usedLength = 0;
  private _warned = false;

  constructor(public maxLength = 65_536) {
    this._data = new Float32Array(maxLength);
    this._usedLength = 0;
  }

  clear() {
    this._usedLength = 0;
  }

  getAndClear(): Float32Array {
    const result = this._data.slice(0, this._usedLength);
    this.clear();
    return result;
  }

  push(array: Float32Array) {
    const numBytes = array.length;
    if (numBytes === 0) {
      return;
    }

    const newLength = this._usedLength + numBytes;
    const toCopy =
      newLength > this.maxLength ? this.maxLength - this._usedLength : numBytes;

    if (newLength > this.maxLength && !this._warned) {
      this._warned = true;
      console.warn('Recording buffer full, started dropping samples');
    }

    if (toCopy > 0) {
      this._data.set(array, this._usedLength);
      this._usedLength += toCopy;
      this._warned = false;
    }
  }
}

/**
 * Recording target that writes the raw samples to a recording in the storage
 * backend.
 */
export class StorageRecordingTarget implements RecordingTarget {
  /** The storage backend that the recording target uses */
  private readonly _storage: StorageBackend;

  /** The writer that is used to access the file on the storage backend */
  private _writer: RecordingWriter | undefined;

  /**
   * Buffer in which the samples are collected until they can be flushed to
   * the backend.
   */
  private readonly _buffer: RecordingBuffer;

  /**
   * ID of the next callback that will flush the buffer.
   */
  private _callbackId: number | undefined;

  /**
   * ID of the recording that was created with this target. Filled when the
   * target is closed.
   */
  private _lastRecordingId: RecordingId | undefined;

  constructor() {
    this._buffer = new RecordingBuffer();
    this._storage = new OpfsStorageBackend();
    this._callbackId = undefined;
    this._lastRecordingId = undefined;
    this._writer = undefined;

    this._flushAndReschedule = this._flushAndReschedule.bind(this);
  }

  async close() {
    const writer = this._writer;
    this._writer = undefined;

    if (this._callbackId) {
      cancelAnimationFrame(this._callbackId);
    }

    await this._flush(writer);

    if (writer) {
      this._lastRecordingId = await writer.close();
    }
  }

  getLastRecordingId(): string | undefined {
    return this._lastRecordingId;
  }

  async prepare(metadata: RecordingMetadata) {
    if (this._writer !== undefined) {
      throw new Error('Storage recording target has already been prepared');
    }

    await this._storage.ensurePrepared();
    this._writer = await this._storage.openRecording(metadata);
  }

  store(update: RecordingUpdate) {
    this._buffer.push(getInterleavedSamples(update));

    if (this._callbackId === undefined) {
      this._callbackId = requestAnimationFrame(this._flushAndReschedule);
    }
  }

  async _flush(writer?: RecordingWriter) {
    const bytes = this._buffer.getAndClear();
    writer ??= this._writer;
    if (writer) {
      await writer.write(bytes);
    }

    return bytes;
  }

  async _flushAndReschedule() {
    const bytes = await this._flush();
    this._callbackId =
      bytes.length > 0
        ? requestAnimationFrame(this._flushAndReschedule)
        : undefined;
  }
}

export default function createStorageRecordingTarget() {
  return new StorageRecordingTarget();
}

const disposer = registerRecordingTargetType(
  'storage',
  createStorageRecordingTarget,
);
import.meta.webpackHot?.dispose(disposer);
