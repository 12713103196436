import Stack, { type StackProps } from '@mui/material/Stack';

export type SidebarProps = Omit<StackProps, 'sx'> & {
  disablePadding?: boolean;
};

/**
 * Component showing a generic styled sidebar.
 */
export default function Sidebar({
  children,
  disablePadding,
  ...rest
}: SidebarProps) {
  return (
    <Stack
      sx={{
        boxSizing: 'border-box',
        backgroundColor: '#181818',
        borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
        display: 'flex',
        flex: 1,
        height: '100%',
        p: disablePadding ? 0 : 2,
        overflowY: 'hidden',

        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.6)',
        '& hr': (theme) => ({
          border: 0,
          backgroundColor: theme.palette.divider,
          height: 2,
          mx: disablePadding ? 0 : -2,
          boxShadow: '0 -2px 4px 1px rgba(0, 0, 0, 0.6)',
        }),
      }}
    >
      {children}
    </Stack>
  );
}
