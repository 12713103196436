import Splitter, { SplitDirection, type SplitProps } from '@devbookhq/splitter';

import { useCallback } from 'react';
import {
  getSidebarWidthPercentage,
  setSidebarWidthPercentage,
} from '~/features/main-window/slice.ts';
import { useAppDispatch, useAppSelector } from '~/hooks/store.ts';

/**
 * Splitter that splits the top-level view in two sections based on the
 * sidebar percentage stored in the state store.
 */
export default function SidebarSplitter({ children, ...rest }: SplitProps) {
  const sidebarWidthPercentage = useAppSelector(getSidebarWidthPercentage);
  const dispatch = useAppDispatch();
  const handleResizeFinished = useCallback(
    (_pairIdx: number, newSizes: number[]) => {
      dispatch(setSidebarWidthPercentage(newSizes[1]));
    },
    [dispatch],
  );
  return (
    <Splitter
      direction={SplitDirection.Horizontal}
      minWidths={[240, 240]}
      {...rest}
      initialSizes={[100 - sidebarWidthPercentage, sidebarWidthPercentage]}
      onResizeFinished={handleResizeFinished}
    >
      {children}
    </Splitter>
  );
}
