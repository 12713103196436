import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from 'react-i18next';
import TopLevelViewWrapper from '~/components/TopLevelViewWrapper.tsx';
import {
  shouldIncreaseRecordingIndexAutomatically,
  updateSettings,
} from '~/features/settings/slice.ts';
import { useAppDispatch, useAppSelector } from '~/hooks/store.ts';
import LanguageSelector from './LanguageSelector.tsx';

/**
 * Component that shows the Settings tab of the main window.
 */
export default function SettingsTab() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const autoIncrease = useAppSelector(
    shouldIncreaseRecordingIndexAutomatically,
  );

  return (
    <TopLevelViewWrapper padding>
      <LanguageSelector />
      <FormControlLabel
        control={
          <Checkbox
            checked={autoIncrease}
            onChange={(event) => {
              dispatch(
                updateSettings({
                  autoIncreaseRecordingIndex: event.target.checked,
                }),
              );
            }}
          />
        }
        label={t('Settings.autoIncreaseRecordingIndex')}
      />
    </TopLevelViewWrapper>
  );
}
