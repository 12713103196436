import Box from '@mui/material/Box';
import Typography, { type TypographyProps } from '@mui/material/Typography';

/**
 * Props of the placeholder component.
 */
export interface PlaceholderProps {
  /** The color of the placeholder text */
  color?: TypographyProps['color'];

  /** The label of the placeholder */
  label: string;
}

/**
 * Component that shows a placeholder text for parts of the user interface that
 * are not ready yet.
 *
 * @param props - the props of the component
 */
export default function Placeholder({ color, label }: PlaceholderProps) {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        opacity: 0.5,
      }}
    >
      <Typography align="center" variant="h2" color={color}>
        {label}
      </Typography>
    </Box>
  );
}
