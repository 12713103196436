import { normalize } from '~/utils/math.ts';

/**
 * Array containing a pre-recorded raw pulse wave signal that can be used as
 * a test signal.
 *
 * The pulse wave was recorded at 400 Hz.
 */
const rawPulseWave: readonly number[] = [
  73.988, 74.032, 74.125, 74.282, 74.515, 74.836, 75.259, 75.789, 76.436,
  77.201, 78.085, 79.085, 80.197, 81.417, 82.738, 84.155, 85.663, 87.258,
  88.934, 90.684, 92.501, 94.373, 96.285, 98.221, 100.16, 102.08, 103.96,
  105.78, 107.53, 109.18, 110.73, 112.18, 113.52, 114.76, 115.89, 116.92,
  117.86, 118.72, 119.49, 120.19, 120.83, 121.41, 121.92, 122.39, 122.8, 123.18,
  123.51, 123.8, 124.05, 124.27, 124.45, 124.6, 124.72, 124.81, 124.87, 124.89,
  124.89, 124.85, 124.79, 124.69, 124.55, 124.39, 124.19, 123.95, 123.68,
  123.38, 123.05, 122.69, 122.29, 121.87, 121.42, 120.95, 120.46, 119.94,
  119.41, 118.87, 118.31, 117.74, 117.17, 116.59, 116, 115.42, 114.83, 114.25,
  113.67, 113.1, 112.53, 111.96, 111.4, 110.86, 110.31, 109.78, 109.26, 108.74,
  108.23, 107.73, 107.24, 106.75, 106.27, 105.8, 105.34, 104.88, 104.43, 103.99,
  103.55, 103.12, 102.7, 102.28, 101.87, 101.47, 101.07, 100.68, 100.3, 99.926,
  99.561, 99.205, 98.858, 98.521, 98.195, 97.878, 97.573, 97.278, 96.995,
  96.723, 96.462, 96.212, 95.973, 95.745, 95.526, 95.316, 95.114, 94.921,
  94.733, 94.55, 94.37, 94.192, 94.014, 93.834, 93.649, 93.458, 93.259, 93.049,
  92.827, 92.591, 92.338, 92.069, 91.783, 91.478, 91.159, 90.825, 90.481, 90.13,
  89.777, 89.431, 89.096, 88.779, 88.486, 88.221, 87.989, 87.792, 87.631,
  87.508, 87.422, 87.374, 87.363, 87.389, 87.453, 87.553, 87.686, 87.852,
  88.047, 88.267, 88.507, 88.762, 89.025, 89.289, 89.551, 89.804, 90.043,
  90.266, 90.471, 90.656, 90.819, 90.962, 91.084, 91.187, 91.273, 91.342,
  91.397, 91.439, 91.469, 91.491, 91.505, 91.513, 91.516, 91.516, 91.513,
  91.509, 91.504, 91.498, 91.493, 91.487, 91.482, 91.478, 91.473, 91.468,
  91.463, 91.456, 91.449, 91.44, 91.429, 91.415, 91.398, 91.378, 91.353, 91.324,
  91.29, 91.251, 91.207, 91.157, 91.102, 91.042, 90.977, 90.908, 90.834, 90.755,
  90.673, 90.587, 90.499, 90.408, 90.314, 90.218, 90.12, 90.021, 89.92, 89.818,
  89.715, 89.611, 89.506, 89.401, 89.294, 89.187, 89.079, 88.97, 88.86, 88.749,
  88.638, 88.525, 88.41, 88.295, 88.178, 88.059, 87.938, 87.816, 87.692, 87.566,
  87.437, 87.307, 87.174, 87.039, 86.902, 86.762, 86.621, 86.477, 86.331,
  86.183, 86.034, 85.883, 85.73, 85.576, 85.421, 85.265, 85.108, 84.951, 84.794,
  84.636, 84.478, 84.322, 84.165, 84.009, 83.854, 83.701, 83.548, 83.398,
  83.248, 83.101, 82.955, 82.812, 82.671, 82.53, 82.393, 82.258, 82.124, 81.993,
  81.865, 81.738, 81.613, 81.49, 81.369, 81.249, 81.131, 81.016, 80.901, 80.788,
  80.678, 80.568, 80.459, 80.352, 80.246, 80.141, 80.037, 79.935, 79.834,
  79.734, 79.635, 79.537, 79.44, 79.345, 79.25, 79.156, 79.064, 78.973, 78.882,
  78.793, 78.705, 78.618, 78.532, 78.447, 78.362, 78.279, 78.197, 78.115,
  78.034, 77.954, 77.875, 77.796, 77.718, 77.641, 77.564, 77.487, 77.411,
  77.335, 77.26, 77.185, 77.111, 77.036, 76.962, 76.889, 76.815, 76.742, 76.669,
  76.596, 76.524, 76.452, 76.38, 76.308, 76.236, 76.165, 76.094, 76.024, 75.953,
  75.883, 75.813, 75.743, 75.674, 75.605, 75.536, 75.467, 75.398, 75.33, 75.262,
  75.194, 75.126, 75.059, 74.991, 74.923, 74.855, 74.788, 74.72, 74.652, 74.585,
  74.517, 74.449, 74.381, 74.314, 74.247, 74.183, 74.123, 74.071, 74.03, 74.009,
];

/**
 * Array containing a pulse wave signal normalized to the range [-1, 1]
 */
export const normalizedPulseWave: readonly number[] = normalize(rawPulseWave, {
  min: -1,
  max: 1,
});
