import TopLevelViewWrapper from '~/components/TopLevelViewWrapper.tsx';
import SidebarSplitter from '~/features/main-window/SidebarSplitter.tsx';
import StorageDataGrid from './StorageDataGrid.tsx';
import StorageSidebar from './StorageSidebar.tsx';

export default function StorageTab() {
  return (
    <TopLevelViewWrapper>
      <SidebarSplitter>
        <StorageDataGrid />
        <StorageSidebar />
      </SidebarSplitter>
    </TopLevelViewWrapper>
  );
}
