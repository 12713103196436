import { createSelector } from '@reduxjs/toolkit';
import { createArraySelector } from 'reselect-map';
import type { AppState } from '~/store.ts';
import { channelAdapter, targetAdapter } from './adapters.ts';
import { createRecordingTarget } from './targets/index.ts';
import type { RecordingTarget } from './targets/types.ts';

const noRecordingTargets: RecordingTarget[] = [];

const channelSelectors = channelAdapter.getSelectors<AppState>(
  (state) => state.recording.channels,
);

const targetSelectors = targetAdapter.getSelectors<AppState>(
  (state) => state.recording.targets,
);

export const getRecordingChannels = channelSelectors.selectAll;
export const getRecordingChannelIds = channelSelectors.selectIds;

export const getRecordingTargetSpecs = targetSelectors.selectAll;

type RecordingTargetSelector = (state: AppState) => RecordingTarget[];

/**
 * Selector that returns an array containing all the recording targets, constructed
 * on-demand when needed.
 */
export const getRecordingTargets: RecordingTargetSelector = createArraySelector(
  getRecordingTargetSpecs,
  createRecordingTarget,
) as any as RecordingTargetSelector;

/**
 * Selector that returns an array containing all the active recording targets, constructed
 * on-demand when needed.
 */
export const getActiveRecordingTargets = createSelector(
  getRecordingTargets,
  (state: AppState) => state.recording.isRecording,
  (targets, isRecording) => (isRecording ? targets : noRecordingTargets),
);
