import { useEffect } from 'react';
import { useAppSelector } from '~/hooks/store.ts';
import i18n from '~/i18n.ts';
import { getLanguage } from '../slice.ts';

/**
 * Component that ensures that the current language of the i18n framework
 * matches the language in the settings.
 */
const LanguageWatcher = () => {
  const language = useAppSelector(getLanguage);

  useEffect(() => {
    void i18n.changeLanguage(language);
  }, [language]);

  return null;
};

export default LanguageWatcher;
