import { Toolbar } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import './App.css';
import MainWindowTabs from './features/main-window/MainWindowTabs.tsx';
import {
  getSelectedTab,
  setSelectedTab,
} from './features/main-window/slice.ts';
import { MainWindowTab } from './features/main-window/types.ts';
import { useAppDispatch, useAppSelector } from './hooks/store.ts';
import AnalyseTab from './views/analyse/AnalyseTab.tsx';
import RecordTab from './views/record/RecordTab.tsx';
import SettingsTab from './views/settings/SettingsTab.tsx';
import StorageTab from './views/storage/StorageTab.tsx';

export default function App() {
  const selectedTab = useAppSelector(getSelectedTab);
  const dispatch = useAppDispatch();

  return (
    <Box
      sx={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <AppBar position="static" sx={{ zIndex: 10 }}>
        <Toolbar>
          <MainWindowTabs
            value={selectedTab}
            onChange={(event, value: MainWindowTab) => {
              dispatch(setSelectedTab(value));
            }}
          />
        </Toolbar>
      </AppBar>
      {selectedTab === MainWindowTab.RECORD && <RecordTab />}
      {selectedTab === MainWindowTab.ANALYSE && <AnalyseTab />}
      {selectedTab === MainWindowTab.STORAGE && <StorageTab />}
      {selectedTab === MainWindowTab.SETTINGS && <SettingsTab />}
    </Box>
  );
}
