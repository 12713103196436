import { addListener, createListenerMiddleware } from '@reduxjs/toolkit';
import { addRecordingListeners } from './features/recording/listeners.ts';
import type { AppDispatch, AppState } from './store.ts';

export const listenerMiddleware = createListenerMiddleware();

export const startAppListening = listenerMiddleware.startListening.withTypes<
  AppState,
  AppDispatch
>();

export const addAppListener = addListener.withTypes<AppState, AppDispatch>();

/** Inferred type of the startAppListening() function */
export type AppStartListening = typeof startAppListening;

export type ListenerSetupFn = (startListening: AppStartListening) => void;

addRecordingListeners(startAppListening);
