import { MiniSignal } from 'mini-signals';
import type { RecordingMetadata } from '../metadata.ts';
import { StorageBackendNotPreparedError } from './errors.ts';
import type {
  RecordingId,
  RecordingMetadataWithId,
  RecordingWriter,
  StorageBackend,
  StorageBackendEvent,
  StorageBackendSubscriber,
} from './types.ts';

export abstract class StorageBackendBase implements StorageBackend {
  /** The path separator character used in the backend */
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/class-literal-property-style
  public static readonly SEPARATOR = '/';

  /** Signal that is emitted when the content of the backend changes significantly */
  private readonly _signal: MiniSignal<[StorageBackendEvent, StorageBackend]>;

  public constructor() {
    this._signal = new MiniSignal();
  }

  public dispose() {
    /* Empty */
  }

  public pathToParts(path: string): string[] {
    return path.split(StorageBackendBase.SEPARATOR).filter(Boolean);
  }

  public subscribe(subscriber: StorageBackendSubscriber): () => void {
    const binding = this._signal.add(subscriber);
    return () => this._signal.detach(binding);
  }

  /**
   * Ensures that this storage backend is prepared for use. Calls {@link prepare()}
   * if needed, otherwise returns immediately.
   */
  public async ensurePrepared(): Promise<void> {
    const prepared = await this.isPrepared();
    if (!prepared) {
      let success = false;

      try {
        success = await this.prepare();
      } catch (error) {
        throw new StorageBackendNotPreparedError(
          'Could not prepare storage backend',
          { cause: error },
        );
      }

      if (!success) {
        throw new StorageBackendNotPreparedError(
          'Could not prepare storage backend',
        );
      }
    }
  }

  abstract erase(): Promise<void>;
  abstract getStorageEstimate(): Promise<StorageEstimate>;
  abstract isPrepared(): Promise<boolean>;
  abstract listRecordings(): Promise<RecordingMetadataWithId[]>;
  abstract openRecording(metadata: RecordingMetadata): Promise<RecordingWriter>;
  abstract removeRecordingById(id: RecordingId): Promise<void>;

  /**
   * Dispatches an event from the storage backend.
   */
  protected dispatchEvent(event: StorageBackendEvent) {
    this._signal.dispatch(event, this);
  }

  /**
   * Prepares the storage backend to be used by the user.
   *
   * A storage backend is consiudered to be prepared for use if the user has
   * permission to read or write arbitrary files and directories on the backend.
   * The purpose of this function is to ask the permission of the user if such a
   * permission is needed by the execution environment.
   *
   * @returns whether the storage backend was prepared successfully
   */
  protected abstract prepare(): Promise<boolean>;
}
