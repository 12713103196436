import i18n, { type Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { debug } from '~/config.ts';
import en from '~/i18n/en/translation.json';
import hu from '~/i18n/hu/translation.json';

// MUI base component localization
import {
  type Localization as MuiLocalization,
  enUS as enUSMuiLocalization,
  huHU as huHUMuiLocalization,
} from '@mui/material/locale';

// MUI data grid localization
import {
  enUS as enUSMuiDataGridLocalization,
  huHU as huHUMuiDataGridLocalization,
} from '@mui/x-data-grid/locales';

export enum Language {
  EN = 'en',
  HU = 'hu',
}

const resources: Resource = {
  en: { translation: en },
  hu: { translation: hu },
};

export const supportedLanguages: Language[] = [Language.EN, Language.HU];

type MuiDataGridLocalization = typeof enUSMuiDataGridLocalization;
type Localization = MuiLocalization | MuiDataGridLocalization;

export const languageToMuiLocalizations: Record<Language, Localization[]> = {
  [Language.EN]: [enUSMuiLocalization, enUSMuiDataGridLocalization],
  [Language.HU]: [huHUMuiLocalization, huHUMuiDataGridLocalization],
};

export const initI18N = async () =>
  // eslint-disable-next-line import/no-named-as-default-member
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,

      lng: Language.EN,
      fallbackLng: Language.EN,
      supportedLngs: supportedLanguages,

      debug,

      // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
      // if you're using a language detector, do not define the lng option

      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    });

export default i18n;
