import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Sidebar from '~/components/Sidebar.tsx';
import EraseStorageListItem from './EraseStorageListItem.tsx';
import RefreshStorageListItem from './RefreshStorageListItem.tsx';

/**
 * Component showing the sidebar of the recording view.
 */
export default function StorageSidebar() {
  return (
    <Sidebar disablePadding>
      <List disablePadding>
        <RefreshStorageListItem />
        <Divider />
        <EraseStorageListItem />
      </List>
      <hr />
    </Sidebar>
  );
}
