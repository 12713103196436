import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import DurationField from '~/components/DurationField.tsx';
import {
  getMetadata,
  isRecording,
  updateMetadata,
} from '~/features/recording/slice.ts';
import { useAppDispatch, useAppSelector } from '~/hooks/store.ts';

/**
 * Component showing the sidebar of the recording view.
 */
export default function MetadataEditor() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isRecordingInProgress = useAppSelector(isRecording);
  const metadata = useAppSelector(getMetadata);
  const canEdit = !isRecordingInProgress;

  const { duration, patientId, index: recordingIndex, sampleRate } = metadata;

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        px: 2,
        '& .MuiTextField-root': { my: 2 },
        '& .MuiFormControl-root': { my: 2 },
      }}
      flex={1}
    >
      <TextField
        label={t('MetadataEditor.patientId.label')}
        disabled={!canEdit}
        value={patientId}
        onChange={(event) => {
          dispatch(updateMetadata({ patientId: event.target.value }));
        }}
        fullWidth
      />
      <TextField
        label={t('MetadataEditor.recordingIndex.label')}
        fullWidth
        disabled={!canEdit}
        value={recordingIndex}
        onChange={(event) => {
          dispatch(updateMetadata({ index: event.target.value }));
        }}
      />
      <DurationField
        label={t('MetadataEditor.duration.label')}
        fullWidth
        disabled={!canEdit}
        value={duration}
        onChange={(duration) => {
          dispatch(updateMetadata({ duration }));
        }}
      />
      <TextField
        label={t('MetadataEditor.sampleRate.label')}
        select
        fullWidth
        disabled={!canEdit}
        value={sampleRate}
        onChange={(event) => {
          const sampleRate = Number(event.target.value);
          if (Number.isFinite(sampleRate) && sampleRate >= 1) {
            dispatch(updateMetadata({ sampleRate }));
          }
        }}
      >
        <MenuItem value={50}>50 Hz</MenuItem>
        <MenuItem value={100}>100 Hz</MenuItem>
        <MenuItem value={200}>200 Hz</MenuItem>
      </TextField>
    </Box>
  );
}
