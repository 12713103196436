import Box, { type BoxProps } from '@mui/material/Box';
import { useMeasure } from '@react-hookz/web';
import isNil from 'lodash-es/isNil';
import { useMemo } from 'react';
import { Group, Layer, Line, Rect, Stage } from 'react-konva';

export interface SimpleLineChartProps extends BoxProps {
  color: string;
  numSamples?: number;
  playheadPosition?: number;
  samples: number[];
  strokeWidth?: number;
  yRange: [number, number];
}

function convertSamplesToPoints(samples: number[], numSamples = 0) {
  const arrayLength = samples.length;
  const lastSample = arrayLength > 0 ? samples.at(-1)! : 0;
  const points = new Array<number>((numSamples + 1) * 2).fill(lastSample);
  let i: number;
  let j: number;

  numSamples = Math.max(numSamples, arrayLength);

  j = 0;
  for (i = 0; i < arrayLength; i++) {
    points[j++] = i;
    points[j++] = samples[i];
  }

  for (i = arrayLength; i <= numSamples; i++) {
    points[j++] = i;
    j++;
  }

  return points;
}

export const SimpleLineChart = ({
  color,
  numSamples = 0,
  playheadPosition,
  samples,
  strokeWidth = 3,
  yRange,
  ...rest
}: SimpleLineChartProps) => {
  const [measurement, ref] = useMeasure<HTMLDivElement>();
  const { width = 300, height = 200 } = measurement ?? {};

  const points = useMemo(
    () => convertSamplesToPoints(samples, numSamples),
    [samples, numSamples],
  );

  const chartGroupProps = useMemo(() => {
    const [minY, maxY] = yRange;
    return {
      scaleY: -height / (maxY - minY),
      offsetY: maxY,
    };
  }, [height, yRange]);

  return (
    <Box ref={ref} {...rest}>
      <Stage height={height} width={width}>
        <Layer scaleX={width / numSamples}>
          {/* Chart area */}
          <Group {...chartGroupProps}>
            <Line
              stroke={color}
              strokeWidth={strokeWidth}
              points={points}
              strokeScaleEnabled={false}
              lineJoin="bevel"
            />
          </Group>

          {/* Playhead shading */}
          {!isNil(playheadPosition) && (
            <Rect
              x={Math.floor(playheadPosition - strokeWidth / 2)}
              y={0}
              width={100}
              height={height}
              fillLinearGradientStartPoint={{ x: 0, y: 0 }}
              fillLinearGradientEndPoint={{ x: 100, y: 0 }}
              fillLinearGradientColorStops={[0.5, '#121212', 1, '#00121212']}
            />
          )}
        </Layer>
      </Stage>
    </Box>
  );
};
