import { UTCDate } from '@date-fns/utc';
import { formatISO } from 'date-fns/formatISO';
import { sha1 } from 'object-hash';
import pMap from 'p-map';

import { isRecordingMetadata } from '../metadata.guard.ts';
import type { RecordingMetadata } from '../metadata.ts';
import { StorageBackendBase } from './base.ts';
import { registerStorageBackendType } from './factory.ts';
import type {
  RecordingId,
  RecordingMetadataWithId,
  RecordingWriter,
} from './types.ts';

/**
 * Specification of the OPFS storage backend.
 */
export type OpfsStorageBackendSpec = { type: 'opfs'; path?: string };

/**
 * Internal implementation of the {@link RecordingWriter} interface for the
 * {@link OpfsStorageBackend}.
 */
class OpfsRecordingWriter implements RecordingWriter {
  constructor(
    private readonly recordingId: string,
    private readonly handle: FileSystemFileHandle,
    private readonly writable: FileSystemWritableFileStream,
  ) {}

  async close(): Promise<string> {
    await this.writable.close();
    return this.recordingId;
  }

  async write(data: BinaryData): Promise<void> {
    await this.writable.write(data);
  }
}

/**
 * Class that implements a storage backend that uses the origin-protected file
 * system browser API.
 */
export class OpfsStorageBackend extends StorageBackendBase {
  _root: FileSystemDirectoryHandle | undefined = undefined;

  /**
   * Creates a new OPFS storage backend instance that stores recording at the
   * given root path.
   *
   * @param path  the root path
   */
  constructor(public readonly path = '/') {
    super();
  }

  async erase(): Promise<void> {
    const root = await this.getRoot();
    const names: string[] = [];

    /* It is unclear whether we could erase while iterating over the directory
     * so we collect all entry names and then do another round to erase */
    for await (const entry of root.values()) {
      names.push(entry.name);
    }

    await pMap(
      names,
      async (name) => root.removeEntry(name, { recursive: true }),
      {
        concurrency: 5,
        stopOnError: false,
      },
    );

    this.dispatchEvent('changed');
  }

  async getStorageEstimate(): Promise<StorageEstimate> {
    return navigator.storage.estimate();
  }

  async isPrepared(): Promise<boolean> {
    return navigator.storage.persisted();
  }

  async listRecordings(): Promise<RecordingMetadataWithId[]> {
    const root = await this.getRoot();
    const result: RecordingMetadataWithId[] = [];

    for await (const entry of root.values()) {
      if (entry.kind !== 'directory') {
        continue;
      }

      let maybeMetadata;

      try {
        const fileHandle = await entry.getFileHandle('meta.json');
        const file = await fileHandle.getFile();
        const contents = await file.text();

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        maybeMetadata = JSON.parse(contents);
      } catch (error) {
        console.warn(
          `Error while listing recordings in storage: ${String(error)}`,
        );
        maybeMetadata = undefined;
      }

      if (isRecordingMetadata(maybeMetadata)) {
        result.push({ ...maybeMetadata, id: entry.name.slice(0, -10) });
      }
    }

    return result;
  }

  async openRecording(metadata: RecordingMetadata): Promise<RecordingWriter> {
    const root = await this.getRoot();
    const recordingId = this.getRecordingIdFromMetadata(metadata);
    const dirHandle = await this.getDirectoryHandleForRecording(recordingId, {
      create: true,
    });

    const metaHandle = await dirHandle.getFileHandle('meta.json', {
      create: true,
    });
    const metaWritable = await metaHandle.createWritable();
    await metaWritable.write(JSON.stringify(metadata));
    await metaWritable.close();

    const handle = await root.getFileHandle('recording.bin', { create: true });
    const writable = await handle.createWritable();

    return new OpfsRecordingWriter(recordingId, handle, writable);
  }

  async removeRecordingById(id: RecordingId): Promise<void> {
    const dirName = this.getDirectoryNameForRecording(id);
    const root = await this.getRoot();
    await root.removeEntry(dirName, { recursive: true });
  }

  protected async prepare(): Promise<boolean> {
    return navigator.storage.persist();
  }

  private getDirectoryNameForRecording(recordingId: RecordingId): string {
    return String(recordingId) + '';
  }

  private async getDirectoryHandleForRecording(
    recordingId: RecordingId,
    { create = false }: { create?: boolean } = {},
  ): Promise<FileSystemDirectoryHandle> {
    const root = await this.getRoot();
    return root.getDirectoryHandle(
      this.getDirectoryNameForRecording(recordingId),
      { create },
    );
  }

  /**
   * Generates a unique recording ID for the given metadata.
   */
  private getRecordingIdFromMetadata(metadata: RecordingMetadata): RecordingId {
    const { startedAt } = metadata;
    const formattedDate = formatISO(new UTCDate(startedAt), {
      format: 'basic',
    });
    const hash = sha1(metadata).slice(0, 12);
    return `${formattedDate}_${hash}`;
  }

  /**
   * Returns the directory handle of the directory storing the recordings in the
   * OPFS storage backend.
   *
   * Also ensures that the storage area is prepared as a side-effect.
   */
  private async getRoot(): Promise<FileSystemDirectoryHandle> {
    if (this._root === undefined) {
      await this.ensurePrepared();

      let folder = await navigator.storage.getDirectory();

      for (const part of this.pathToParts(this.path)) {
        // eslint-disable-next-line no-await-in-loop
        folder = await folder.getDirectoryHandle(part, { create: true });
      }

      this._root = folder;
    }

    return this._root;
  }
}

export default function createOpfsStorageBackend(spec: OpfsStorageBackendSpec) {
  const { path } = spec;
  return new OpfsStorageBackend(path);
}

const disposer = registerStorageBackendType('opfs', createOpfsStorageBackend);
import.meta.webpackHot?.dispose(disposer);
