import type { RecordingTargetFactory, RecordingTargetSpec } from './types.ts';

/**
 * Registry that maps recording target specifications to the functions that
 * create the corresponding recording targets.
 */
const _registry: Record<string, RecordingTargetFactory> = {};

/**
 * Creates a recording target object from its specification.
 */
export function createRecordingTarget(spec: RecordingTargetSpec) {
  const { type } = spec;
  const factory = _registry[type];
  if (factory) {
    return factory(spec);
  }

  throw new Error(`Unknown recording target type: ${type}`);
}

/**
 * Registers a recording target type.
 *
 * @param type the type to register
 * @param factory the factory function to call when a target with this type
 *        needs to be constructed
 *
 * @returns a disposer function that can be called to unregister the type
 */
export function registerRecordingTargetType(
  type: string,
  factory: RecordingTargetFactory,
): () => void {
  if (_registry[type]) {
    throw new Error(`Recording target type is already registered: ${type}`);
  }

  _registry[type] = factory;

  return () => {
    unregisterRecordingTargetType(type);
  };
}

/**
 * Unregisters a recording target type.
 *
 * @param type the type to unregister
 */
export function unregisterRecordingTargetType(type: string) {
  delete _registry[type]; // eslint-disable-line @typescript-eslint/no-dynamic-delete
}
