import { createSelector } from '@reduxjs/toolkit';
import { createStorageBackend } from '~/model/storage/index.ts';
import { getStorageBackendSpec } from './slice.ts';

/**
 * Returns the storage backend currently in use by the application.
 */
export const getStorageBackend = createSelector(
  getStorageBackendSpec,
  createStorageBackend,
);
